type Nullable<T> = T | null;

enum BankAccountTypeEnum {
  Checking = "Checking",
  Savings = "Savings",
}

interface BankAccountType {
  account_name: string;
  account_number: string;
  bank_account_type: BankAccountTypeEnum;
  bank_name: string;
  routing_number: string;
}

interface TrxPropsType {
  existing_debit_card_last_4: number;
  user_trx_form_token: string;
  access_trx: boolean;
  trx_pay_page_url: string;
  trx_client: string;
  trx_pay_page_guid: string;
  trx_redirect_to: string;
  logoMc: string;
  debit_card_last_4?: number;
  debit_card_guid?: string;
  debit_card_updated?: boolean;
  allow_debit_card?: boolean;
}

interface DebtorType {
  caseNumber: string;
  city: string;
  dateOfBirth: string;
  email: string;
  id: number;
  fullName: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  phone1: string;
  phone2?: string;
  phone3?: string;
  routingNumber: string;
  state: string;
  status: string;
  street1: string;
  street2: string;
  trusteeName: string;
  wageNumber: string;
  wagePull: boolean;
  zip: string;
  hasNoBankruptcyCase: boolean;
}

interface PaymentScheduleType {
  dayNumber?: number;
  firstPayment?: string;
  firstPaymentDay?: number;
  nextPayment?: string;
  secondPaymentDay?: number;
  type: ScheduleTypeEnum;
  weekNumber?: number;
}

interface EmployerType {
  bankAccount: Nullable<BankAccountType>;
  corporate: boolean;
  routingNumber: string;
  schedule: Nullable<PaymentScheduleType>;
  wageNumber: string;
  id?: number;
}

interface ReceiptType {
  accounting_system: string;
  date: string;
  path: {
    extended: string;
    full: string;
    html: string;
    pdf: string;
    pdf_extended: string;
  };
  viewed_at: string;
}

enum ScheduleTypeEnum {
  BI_WEEKLY = "bi_weekly",
  MONTHLY = "monthly",
  NTH_WEEKDAY = "nth_weekday",
  PARTICULAR_DAY = "particular_day",
  TWICE_MONTHLY = "twice_monthly",
  WEEKLY = "weekly",
}

interface TransactionType {
  amount: string;
  caseNumber: string;
  creditor: string;
  date: string;
  id: number;
  tfsFees: string;
  amountWithFees: string;
}

enum TransferTypeEnum {
  PULL = "PULL",
  PUSH = "PUSH",
}

interface WageScheduleType {
  amountDeductedPerPaycheck: number;
  amountDeductedPerPaycheckWithFee: number;
  amountPerMonth: number;
  scheduleType: string;
}

export {
  BankAccountType,
  BankAccountTypeEnum,
  DebtorType,
  EmployerType,
  Nullable,
  PaymentScheduleType,
  ReceiptType,
  ScheduleTypeEnum,
  TransactionType,
  TransferTypeEnum,
  TrxPropsType,
  WageScheduleType,
};
